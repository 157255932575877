import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
  const meta = to.meta
  const params = to.params

  const isLoggedIn = store.getters['user/is_logged_user']

  if (to.name === 'default' && !isLoggedIn) { // start page & not logout
    return next()
  }

  if (!isLoggedIn) { // if not logged go to default page
    return next({ name: 'default' })
  }

  if (isLoggedIn) {

    if (!to.name) { // page doesn't found
      return next({ name: 'system' })
    }

    const role = store.getters['user/role']

    if (to.name === 'user_has_no_privs' || to.name === 'default') { // if default page try to move
      // check if this user can to to system page?
      const system_route = router.getRoutes().filter(item => item.name === 'system.components_versions')[0]

      if (system_route.meta.permissions.roles.includes(role)) {
        return next({ name: 'system' })
      }
    }

    if (meta && meta.permissions) {
      const permissions = meta.permissions
      const roles = permissions.roles

      if (roles.includes(role)) { // use authorized and has priv to view this page
        return next()
      } else {

        if (to.name === 'system' || to.name === 'system.components_versions') { // user already on system page and not has privs?
          return next({ name: 'user_has_no_privs' })
        }

        if (to.name === 'user_has_no_privs') { // user went to system page
          return next()
        }

        return next({ name: 'system' }) // if no - try to move him to system page

      }
    } else {
      return next()
    }
  }

})

router.afterEach(() => {
})

router.onError(() => {
})
