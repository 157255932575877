<template>
    <el-aside width="230px" v-if="isAuthorized">
        <el-menu :default-openeds="openMenu" :default-active="pageName"
                 @select="handleSelect">
            <el-sub-menu index="1" v-if="hasRole(['service_desk', 'super_admin'])">
                <template #title>
                    <el-icon>
                        <Operation/>
                    </el-icon>
                    <span>System Settings</span>
                </template>
                <el-menu-item v-if="hasRole(['service_desk', 'super_admin'])" index="system.components_versions">
                    <template #title>
                        <el-icon>
                            <KnifeFork/>
                        </el-icon>
                        <span>Components Versions</span>
                    </template>
                </el-menu-item>
                <el-menu-item v-if="hasRole(['super_admin'])" index="system.components">
                    <template #title>
                        <el-icon>
                            <Setting/>
                        </el-icon>
                        <span>Settings</span>
                    </template>
                </el-menu-item>
                <el-menu-item v-if="hasRole(['service_desk', 'super_admin'])" index="system.shared_session">
                    <template #title>
                        <el-icon>
                            <User/>
                        </el-icon>
                        <span>Shared Session</span>
                    </template>
                </el-menu-item>
                <el-menu-item v-if="hasRole(['super_admin'])" index="system.auth_providers">
                    <template #title>
                        <el-icon>
                            <Tools/>
                        </el-icon>
                        <span>Auth Providers</span>
                    </template>
                </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="2" v-if="hasRole(['service_desk', 'super_admin'])">
                <template #title>
                    <el-icon>
                        <Memo/>
                    </el-icon>
                    <span>License</span>
                </template>
                <el-menu-item index="license.constructor" v-if="hasRole(['super_admin']) && host_with_license">
                    <template #title>
                        <el-icon>
                            <Edit/>
                        </el-icon>
                        <span>Constructor</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="license.current" v-if="hasRole(['service_desk', 'super_admin'])">
                    <template #title>
                        <el-icon>
                            <Tickets/>
                        </el-icon>
                        <span>Current</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="license.new" v-if="hasRole(['super_admin'])">
                    <template #title>
                        <el-icon>
                            <Upload/>
                        </el-icon>
                        <span>Upload New</span>
                    </template>
                </el-menu-item>
            </el-sub-menu>
            <el-menu-item index="limits_main" v-if="hasRole(['super_admin'])">
                <template #title>
                    <el-icon>
                        <List/>
                    </el-icon>
                    <span>Limits</span>
                </template>
            </el-menu-item>
        </el-menu>
    </el-aside>

</template>

<script setup>

  import {
    Operation,
    KnifeFork,
    Setting,
    User,
    Tools,
    Memo,
    Edit,
    Tickets,
    Upload,
    List
  } from '@element-plus/icons-vue'

  import { useRoute, useRouter } from 'vue-router'
  import { ref, watch, computed, onMounted } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()
  const route = useRoute()
  const router = useRouter()

  const pageName = ref(route.name)

  const sideBarMap = {
    'system.components': ['1'],
    'system.shared_session': ['1'],
    'system.auth_providers': ['1'],
    'license.new': ['2'],
    'license.current': ['2'],
    'license.constructor': ['2'],
    'license.admin': ['2']
  }

  watch(pageName, () => {
    const meta = route.meta
    if (meta.parent_name) {
      pageName.value = meta.parent_name
    }
  })

  onMounted(() => {
    const meta = route.meta
    if (meta.parent_name) {
      pageName.value = meta.parent_name
    }
  })

  const isAuthorized = computed(() => store.getters['user/is_logged_user'])

  const openMenu = computed(() => sideBarMap[pageName] || ['1'])
  const role = computed(() => store.getters['user/role'])
  const host_with_license = computed(() => store.getters['app/host_with_license'])

  const hasRole = (allowedRoles) => allowedRoles.includes(role.value)

  const handleSelect = (key) => {
    router.push({ name: key }).catch(_ => {})
  }

</script>

