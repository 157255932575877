<template>
    <el-container :style="{ height: containerHeight + 'px' }">
        <div>
            <router-view></router-view>
        </div>
    </el-container>
</template>

<script setup>

  import { useStore } from 'vuex'

  const store = useStore()

  import { computed } from 'vue'

  const containerHeight = computed(() => store.state.app.windowHeight - 80)

</script>

<style scoped>
    div {
        padding: 10px;
        width: 100%;
        height: 100%
    }
</style>
