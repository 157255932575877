import api from '@/api/limits'

const state = {
  req_id: 0,
  limits: [],
  breadcrumb: [],

  is_api_key_search: false,
  last_action: 'list', // list | search

  last_search: '',

  // user data
  obj_type: null,
  company_id: null,
  user_id: null,
  user_titles: null,
  loading_server_data: false
}
const getters = {
  last_action: state => {
    if (state.last_action === 'search' && state.is_api_key_search) return 'api_key_search'
    if (state.last_action === 'search' && !state.is_api_key_search) return 'search'
    else return 'list'
  },
  last_search: state => state.last_search,
  is_api_key_search: state => state.is_api_key_search,
  req_id: state => state.req_id,
  loading: state => state.loading_server_data,
  data: state => state.limits,
  breadCrumb: state => state.breadcrumb.map(item => {
    item.id = item.obj_id + '_' + item.obj_type + '_' + item.company_id + '_' + item.user_id
    item.title = item.obj_type + '(' + item.obj_id + ')'
    return item
  }),
  searchSpace: state => {
    if (!state.breadcrumb.length) return 'account'
    else {
      const last = state.breadcrumb.slice(-1).pop()
      if (last.obj_type === 'account' && last.obj_id) {
        return 'company'
      }
      if (last.obj_type === 'user' && last.obj_id && last.company_id === '0') {
        return 'company'
      }
      if (last.obj_type === 'user' && last.obj_id && last.company_id !== '0') {
        return 'conv'
      }
      if (last.obj_type === 'company') {
        return 'user'
      }
      return null
    }
  }
}

const actions = {
  api_key_search({ commit }, boolean) {
    commit('api_key_search', boolean)
  },
  load_limits({ commit }, params) {
    commit('loading')
    const res = api.list(params.obj_id, params.obj_type, params.user_id, params.company_id)

    if (!params.obj_id && !params.obj_type) {
      commit('clear')
    }

    commit('inc_req')

    return res
      .then(data => data.data)
      .then(data => {
        if (data.breadcrumb) {
          data.breadcrumb.forEach(item => {
            commit('fill_actual_params', item)
          })
        }
        commit('response_list', data.list)
        commit('response_breadcrumb', data.breadcrumb)
        commit('last_action', 'list')
        commit('loaded')
      })
  },
  search_limits({ commit, getters, state }, search) {
    if (!search || search.length < 3) return
    commit('loading')
    commit('fill_last_search', search)
    const res = api.search((getters.is_api_key_search) ? 'api_key' : getters.searchSpace, search, state.user_id, state.company_id)

    return res
      .then(data => data.data)
      .then(data => {
        commit('response_search', data.list)
        commit('last_action', 'search')
        commit('loaded')
      })
  }
}

const mutations = {
  fill_last_search(state, query) {
    state.last_search = query
  },
  last_action(state, action) {
    state.last_action = action
  },
  api_key_search(state, boolean) {
    state.is_api_key_search = boolean
  },
  inc_req(state) {
    state.req_id++
  },
  clear(state) {
    state.obj_type = null
    state.company_id = null
    state.user_id = null
    state.user_titles = null
  },
  update_limit(state, { index, limit }) {
    state.limits[index] = limit
  },
  response_list(state, data) {
    if (data) {
      let list = add_id_into_server_resp(data)
      list = patch_server_resp(state, list)
      state.limits = list
    } else {
      state.limits = []
    }
  },
  response_search(state, data) {
    if (data){
      state.limits = add_id_into_server_resp(data)
    }
  },
  response_breadcrumb(state, data) {
    state.breadcrumb = (!data) ? [] : data
  },
  fill_actual_params(state, params) {
    if (!params) return

    state.obj_type = params.obj_type

    if (params.obj_type === 'account') {
      state.user_id = params.obj_id
      state.user_titles = params.titles // titles
    }

    if (params.obj_type === 'company') {
      state.company_id = params.obj_id
    }
  },
  loading(state) {
    state.loading_server_data = true
    state.limits = []
  },
  loaded(state) {
    state.loading_server_data = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// UTILS

// add id into server response
const add_id_into_server_resp = (data) => {
  return data.map(item => {
    const obj_id = item.obj_id
    const obj_type = item.obj_type
    const company_id = item.company_id
    item.id = obj_id + '_' + obj_type + '_' + company_id
    return item
  })
}

const patch_server_resp = (state, data) => {
  if (state.obj_type === 'account') {
    if (!data.some(item => item.obj_type === 'company' && item.company_id === null)) {
      data.unshift({
        company_id: null,
        limits: [],
        obj_id: null,
        obj_type: 'company',
        titles: [],
        user_id: state.user_id
      })
    }
  }
  if (state.obj_type === 'company') {
    if (!data.some(item => item.obj_type === 'user' && item.obj_id === state.user_id)) {
      data.unshift({
        company_id: state.company_id,
        limits: [],
        obj_id: state.user_id,
        obj_type: 'user',
        titles: state.user_titles,
        user_id: state.user_id
      })
    }
  }
  return data
}
