import { request, get_path } from '@/utils/util'

class LicenceAPI {
  get(obj_type) {
    return this._request({ type: 'get', obj_type })
  }

  invalidate() {
    return this._request({ type: 'invalidate' })
  }

  show() {
    return this._request({ type: 'show' })
  }

  accept() {
    return this._request({ type: 'accept' })
  }

  decline() {
    return this._request({ type: 'decline' })
  }

  upload_as_base64(content) {
    return this._request({ type: 'upload', content })
  }

  _request(data_obj) {
    const opts = { showGlobalError: false }

    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        ...data_obj,
        obj: 'license'
      }
    }, opts)
  }
}

export default LicenceAPI