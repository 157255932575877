import { request } from '@/utils/util'

export default {
  getSettings() {
    return request({
      url: '/system/conf', // not modified because it contains superadmin url
      method: 'get'
    })
  }
}
