import { createWebHistory, createRouter } from "vue-router";

import { publicPath } from '../../vue.config.js'

/* Layout */
import Layout from '@/views/layout/index'

/** note: submenu only apppear when children.length>=1
 *   detail see
 * https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child
 * routes length if not set alwaysShow, only more than one route under the children it will becomes
 * nested mode, otherwise not show the root menu redirect: noredirect           if
 * `redirect:noredirect` will no redirct in the breadcrumb name:'router-name'             the name
 * is used by <keep-alive> (must set!!!) meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
 **/
export const constantRouterMap = [
  // { path: '/license', component: () => import('@/views/license/index') },
  // { path: '/system', component: () => import('@/views/license/index') },
  {
    path: '/',
    component: Layout,
    name: 'default',
    children: [{
      path: '',
      name: 'user_has_no_privs',
      component: () => import('@/views/default/index.vue')
    }]
  },
  {
    path: '/sys',
    component: Layout,
    name: 'system',
    redirect: { name: 'system.components_versions' },
    children: [{
      path: 'settings',
      component: () => import('@/views/system/components/index'),
      name: 'system.components',
      meta: {
        title: 'Settings',
        permissions: {
          roles: ['super_admin'],
          redirect: 'system.components_versions'
        }
      }
    }, {
      path: 'shared_session',
      component: () => import('@/views/system/shared_session/index'),
      name: 'system.shared_session',
      meta: {
        title: 'Shared Session',
        permissions: {
          roles: ['service_desk', 'super_admin'],
          redirect: 'system.components_versions'
        }
      }
    }, {
      path: 'components_versions',
      component: () => import('@/views/system/versions/index'),
      name: 'system.components_versions',
      meta: {
        title: 'Components Versions',
        permissions: {
          roles: ['service_desk', 'super_admin']
        }
      }
    }, {
      path: 'auth_providers',
      component: () => import('@/views/system/auth_providers/index'),
      name: 'system.auth_providers',
      meta: {
        title: 'Auth Providers',
        permissions: {
          roles: ['super_admin']
        }
      },
      children: [{
        path: 'new/:provider_path',
        component: () => import('@/views/system/auth_providers/index'),
        name: 'system.auth_providers_new',
        meta: {
          parent_name: 'system.auth_providers',
          title: 'Auth Providers New',
          permissions: {
            roles: ['super_admin']
          }
        }
      }, {
        path: 'edit/:provider_path',
        component: () => import('@/views/system/auth_providers/index'),
        name: 'system.auth_providers_edit',
        meta: {
          parent_name: 'system.auth_providers',
          title: 'Auth Providers Edit',
          permissions: {
            roles: ['super_admin']
          }
        }
      }]
    }]
  },
  {
    path: '/license',
    component: Layout,
    children: [{
      path: 'request',
      component: () => import('@/views/license/request/index'),
      name: 'license.new',
      meta: {
        title: 'License Request',
        permissions: {
          roles: ['super_admin']
        }
      }
    }, {
      path: 'current',
      component: () => import('@/views/license/current/index'),
      name: 'license.current',
      meta: {
        title: 'License Current',
        permissions: {
          roles: ['service_desk', 'super_admin']
        }
      }
    }, {
      path: 'constructor',
      component: () => import('@/views/license/constructor/index'),
      name: 'license.constructor',
      meta: {
        title: 'License Constructor',
        permissions: {
          roles: ['super_admin']
        }
      }
    }]
  },
  {
    path: '/superadmin/limits',
    component: Layout,
    children: [{
      path: '',
      component: () => import('@/views/limits/index.vue'),
      name: 'limits_main',
      meta: {
        title: 'Limits',
        permissions: {
          roles: ['super_admin']
        }
      }
    }, {
      path: ':obj_id/:obj_type/:company_id/:user_id',
      component: () => import('@/views/limits/index.vue'),
      name: 'limits_router',
      meta: {
        title: 'Limits',
        permissions: {
          roles: ['super_admin']
        }
      }
    }, {
      path: ':obj_id/:obj_type/:company_id/:user_id/search/:search',
      component: () => import('@/views/limits/index.vue'),
      name: 'limits_router_with_search',
      meta: {
        title: 'Limits',
        permissions: {
          roles: ['super_admin']
        }
      }
    }]
  },
  {
    path: '/(.*)', redirect: { name: 'system.components' }
  }

]

export default new createRouter({
  history: createWebHistory(publicPath),
  routes: constantRouterMap
})
