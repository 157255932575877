import { request, get_path } from '@/utils/util'

export default {
  // SHARED SESION
  getSharedSession() {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'show',
        obj: 'shared_session'
      }
    })
  },

  setSharedSession(user_id) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'set',
        obj: 'shared_session',
        obj_id: user_id
      }
    })
  },

  deleteSharedSession() {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'delete',
        obj: 'shared_session'
      }
    })
  },

  listUsers(name, obj_type, limit, offset) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'list',
        obj: 'user',
        order: 'asc',
        order_by: 'id',
        obj_type,
        name,
        limit,
        offset
      }
    })
  }

}
