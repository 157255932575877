<template>
    <el-row align="middle" v-if="inSharedSessionOpened">
        <el-button type="danger" @click="onDisconnect">Disconnect</el-button>
        <el-divider direction="vertical"></el-divider>
        <b>Logged as {{session.id}}</b>
        <el-divider direction="vertical"></el-divider>
        <b>{{session.nick}}</b>
        <el-divider direction="vertical"></el-divider>
    </el-row>
</template>

<script setup>

  import { computed } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()

  const inSharedSessionOpened = computed(() => store.getters['shared_session/isOpened'])

  const session = computed(() => {
    if (inSharedSessionOpened) {
      return store.state.shared_session
    }
    return {}
  })

  const onDisconnect = async() => {
    await store.dispatch('shared_session/delete_shared_session')
  }
</script>
