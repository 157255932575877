import api from '@/api/components'

const _DEFAULT_TAB = 'ALL'

const state = {
  items_as_obj: {},
  uniq_tags: [],
  items_name_by_tag: {},
  activeTab: _DEFAULT_TAB
}

const actions = {
  get_list({ commit }) {
    return new Promise((resolve) => {
      return api.fetchList()
        .then(data => data.data)
        .then(data => {
          commit('convert_data_to_obj', data.list)
          commit('make_uniq_tags', data.list)
          commit('make_items_name_by_tag', data.list)
          resolve(data)
          return data
        })
    })
  }
}

const mutations = {
  convert_data_to_obj(state, items) {
    items.forEach(item => {
      const key = item.key
      state.items_as_obj[key] = item
    })
  },
  make_uniq_tags(state, items) {
    // calculate tags and count by tag
    const tag_count_obj = items.reduce((acc0, { tags }) => {
      return tags.reduce((acc1, { tag }) => {
        if (!acc1[tag]) acc1[tag] = 0
        acc1[tag]++
        return acc1
      }, acc0)
    }, {})

    const res_arr = []

    // make arr from obj
    for (let key in tag_count_obj) {
      if (tag_count_obj.hasOwnProperty(key)) {
        res_arr.push({ 'label': key, size: tag_count_obj[key] })
      }
    }

    state.uniq_tags = res_arr
  },
  make_items_name_by_tag(state, items) {
    // calculate tags and count by tag
    state.items_name_by_tag = {}
    items.forEach(item => {
      item.tags.forEach(({ tag }) => {
          if (!state.items_name_by_tag[tag]) {
            state.items_name_by_tag[tag] = []
          }
          state.items_name_by_tag[tag].push(item.key)
        }
      )
    })
  },
  change_item(state, { key, data }) {
    state.items_as_obj[key] = data
  },
  tab_activate(state, name) {
    state.activeTab = name
  }
}

const getters = {
  uniq_tags: state => {
    const { items_as_obj, uniq_tags } = state

    const uniq_tags1 = [...uniq_tags]
    uniq_tags1.push({ 'label': _DEFAULT_TAB, size: Object.keys(items_as_obj).length })

    // bubble
    return uniq_tags1.sort((a, b) => {
      if (a.size < b.size) {
        return 1
      }
      if (a.size > b.size) {
        return -1
      }
      return 0
    })
  },

  items_by_active_tag: state => {
    const { items_as_obj, items_name_by_tag, activeTab } = state

    if (activeTab === _DEFAULT_TAB) {
      return Object.values(items_as_obj)
    } else {

      const tag_items = items_name_by_tag[activeTab]

      return tag_items.map(name => items_as_obj[name])
    }

  },

  item_by_name: state => name => {
    const { items_as_obj } = state
    return items_as_obj[name]
  },

  activeTab: state => state.activeTab

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
