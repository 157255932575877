<template>
    <router-view/>
</template>

<script setup>

  import { onMounted } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()

  onMounted(() => {
    window.addEventListener('resize', () => {
      store.commit('app/change_window_height', window.innerHeight)
    })
    store.commit('app/change_window_height', window.innerHeight)
  })

</script>

<style>
    body {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        font-size: 11pt;
    }
</style>