import { request, get_path } from '@/utils/util'

class LicenceAttrsAPI {
  ping() {
    return this._request({ obj: 'license_attrs', type: 'ping' })
  }

  list() {
    return this._request({ obj: 'license_attrs', filter: 'actived', type: 'list' })
  }

  delete(id) {
    return this._request({ obj: 'license_attr', type: 'delete', id })
  }

  create(name, attr_type, description, default_value) {
    return this._request({
      obj: 'license_attr',
      type: 'create',
      name,
      attr_type,
      description,
      default_value
    })
  }

  modify(id, name, attr_type, description, default_value) {
    return this._request({
      obj: 'license_attr',
      type: 'update',
      id,
      name,
      attr_type,
      description,
      default_value
    })
  }

  show(id) {
    return this._request({
      obj: 'license_attr',
      type: 'view',
      id
    })
  }

  _request(data_obj) {
    const opts = { showGlobalError: false }

    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        ...data_obj
      }
    }, opts)
  }
}

export default LicenceAttrsAPI

//
// const ops = {
//   showGlobalError: false
// } // hide away info about any errors
//
// const post_obj = {
//   'url': '/api/1/json',
//   method: 'post',
//   data: {
//     obj: 'license'
//   }
// }
//
// const request1 = (obj) => {
//   return request({
//     url: '/api/1/json',
//     method: 'post',
//     data: {
//       ...obj,
//       obj: 'license'
//     }
//   }, ops)
// }
//
// export default {
//   getLicense(obj_type) {
//     return request1({ type: 'get', obj_type })
//   },
//   invalidate() {
//     return request1({ type: 'invalidate' })
//   },
//   showLicense() {
//     return request1({ type: 'show' })
//   },
//   accept() {
//     return request1({ type: 'accept' })
//   },
//   decline() {
//     return request1({ type: 'decline' })
//   }
// }
