import License from '@/api/license'

const api = new License()

const state = {
  prod_license_content: '',
  sandbox_license_content: '',
  loading: false
}

const mutations = {
  loading(state, bool) {
    state.loading = bool
  },
  set_prod_license_content(state, content) {
    state.prod_license_content = content
  },
  set_sandbox_license_content(state, content) {
    state.sandbox_license_content = content
  }
}

const actions = {
  async showLicense({ commit }) {
    const tasks = [
      api.get('file'),
      api.show()
    ]

    const results = await Promise.all(tasks)
    // drop old cache
    commit('set_prod_license_content', '')
    commit('set_sandbox_license_content', '')

    let data = results[0].data

    if (data.result === 'ok') {
      commit('set_prod_license_content', data)
    }

    data = results[1].data
    if (data.result === 'ok') {
      commit('set_sandbox_license_content', data)
    }

    return true
  }

}

const getters = {
  is_valid_sandbox_license: state => state.sandbox_license_content !== '',
  is_valid_prod_license: state => state.prod_license_content !== ''
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
