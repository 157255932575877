import user from '@/api/user'

const state = {
  loaded: false,
  user_id: null,
  nick: null,
  is_super_user: false,
  is_service_desk_user: false
}

const mutations = {
  me(state, data) {
    if (data.result === 'ok') {
      state.user_id = data.user_id
      state.nick = data.nick
      state.is_super_user = data.is_super_user
      state.is_service_desk_user = data.is_service_desk_user
    }
    state.loaded = true
  }
}

const actions = {
  load_me({ commit }) {
    return user.getMe()
      .then(data => data.data)
      .then(data => {
        commit('me', data)
        return data
      })
  }
}

const getters = {
  is_super_user: state => state.is_super_user,
  is_logged_user: state => state.loaded && !!state.user_id,
  role: state => {
    if (state.is_super_user) return 'super_admin'
    if (state.is_service_desk_user) return 'service_desk'
    return 'user'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
