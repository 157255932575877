import { ElMessage } from 'element-plus'

import shared_session from '@/api/shared_session'

const state = {
  loaded: false,
  id: null,
  nick: null
}

const mutations = {
  shared_session(state, data) {
    if (data.length || data.user_id) {
      state.id = data.user_id
      state.nick = data.nick
    }
    state.loaded = true
  },
  enter(state, { id, nick }) {
    state.id = id
    state.nick = nick
  }
}

const actions = {
  load_shared_session({ commit }) {
    return shared_session.getSharedSession()
      .then(result => result.data)
      .then(result => {
        const data = result.data
        commit('shared_session', data)
        return data
      })
  },

  delete_shared_session({ commit, state }) {
    return shared_session.deleteSharedSession()
      .then(_ => {
        ElMessage({
          message: 'Disconnected from ' + state.nick + '\'s session',
          type: 'info',
          duration: 5 * 1000
        })
        commit('enter', { id: null, nick: null })
      })
  }
}

const getters = {
  isOpened: state => !!(state.loaded && state.id && state.nick),
  isConnected: state => id => state.loaded && state.id === id
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
