import site from '@/api/site'
import LicenseAttrs from '@/api/license_attrs'

const license_api = new LicenseAttrs()

import { publicPath } from '../../../vue.config'

const state = {
  loaded: false,
  windowHeight: 0,
  settings: {},
  host_with_license: false
}

const getters = {
  settings: state => state.settings,
  superadmin_path: () => publicPath,

  host_with_license: () => state.host_with_license
}

const mutations = {
  settings(state, data) {
    state.settings = data
  },
  change_window_height(state, height) {
    state.windowHeight = height
  },
  activate_host_with_license(state) {
    state.host_with_license = true
  }
}

const actions = {
  get_settings: ({ commit }) => {
    return site.getSettings()
      .then(data => data.data)
      .then(data => {
        commit('settings', data)
        return data
      })
  },
  ping_license_attrs: ({ commit }) => {
    return license_api.ping()
      .then(data => {
        if (data.data && data.data.pong) {
          commit('activate_host_with_license')
        }
        return true
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}