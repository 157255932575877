import { request, get_path } from '@/utils/util'

const ops = {
  showGlobalError: false
} // hide away info about any errors

export default {
  list(obj_id, obj_type, user_id, company_id) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'list',
        obj: 'limits',
        obj_id,
        obj_type,
        user_id,
        company_id,
        with_breadcrumb: true
      }
    }, ops)
  },
  set(obj_id, obj_type, company_id, user_id, limits, force = false) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'set',
        obj: 'limits',
        obj_id,
        obj_type,
        company_id,
        user_id,
        limits,
        force
      }
    })
  },
  search(filter, pattern, user_id, company_id) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'list',
        obj: 'limits',
        limit: 100,
        user_id,
        company_id,
        filter,
        pattern
      }
    })
  },
  list_history(obj_id, obj_type, company_id, user_id) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'list',
        obj: 'limits_history',
        limit: 100,
        obj_id,
        obj_type,
        company_id,
        user_id
      }
    })
  },
  list_bread_crumb(obj_id, obj_type, company_id, user_id) {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'breadcrumb',
        obj: 'limits',
        obj_id,
        obj_type,
        company_id,
        user_id
      }
    })
  }
}
