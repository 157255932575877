import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import ElementPlus from 'element-plus'
import "element-plus/dist/index.css"
import "element-plus/theme-chalk/dark/css-vars.css"

import locale from 'element-plus/dist/locale/en.mjs'

import VueClipboard from 'vue-clipboard-plus'

import store from './store';

import './permissions' // permission control

const app = createApp(App)

app.use(ElementPlus, {locale})
app.use(VueClipboard)

app.use(store)

async function initializeSystem() {
  await store.dispatch('app/get_settings')
  await store.dispatch('user/load_me')
  await store.dispatch('app/ping_license_attrs')
}

initializeSystem().then(() => {
  app.use(router)

  router.isReady().then(() => {
    app.mount("#app")
  })

})