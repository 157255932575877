<template>
    <el-switch
            v-model="isDark"
            :active-action-icon="Moon"
            :inactive-action-icon="Sunny">
    </el-switch>
</template>


<script setup>
  import { useDark } from "@vueuse/core";

  const isDark = useDark();

  import { Sunny, Moon } from '@element-plus/icons-vue'
  //const toggleDark = useToggle(isDark);
</script>