const BASE_API = process.env.BASE_API;

const fetchService = {
  // create a fetch instance
  create: () => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': '/',
      'Cache-Control': 'no-cache'
    };

    return {
      get: async (url, params) => {
        const queryString = new URLSearchParams(params).toString();
        const response = await fetch(`${url}?${queryString}`, {
          method: 'GET',
          headers,
          credentials: 'include'
        });
        return handleResponse(response);
      },

      post: async (url, data) => {
        const response = await fetch(`${url}`, {
          method: 'POST',
          headers,
          credentials: 'include',
          body: JSON.stringify(data)
        });
        return handleResponse(response);
      },

      // Add other HTTP methods as needed
    };
  },
};

const handleResponse = async (response) => {
  // if (!response.ok) {
  //   // Assuming Message is some kind of notification system
  //   const error = await response.json().catch(() => ({ message: 'Failed to parse error message.' }));
  //   console.error('Fetch Error:', error);
  //   throw error;
  // }

  if (response.status === 0) {
    throw new Error('Network error or timeout');
  }

  let responseBody;

  try {
    responseBody = await response.text();
    return JSON.parse(responseBody);
  } catch (error) {
    //console.warn('Failed to parse response as JSON:', error);
    return responseBody; // Return the raw response text if JSON parsing fails
  }

};

const fetchInstance = fetchService.create();

export default fetchInstance;
