import { request } from '@/utils/util'

export default {
  getMe() {
    return request({
      url: '/auth/me',
      method: 'get'
    })
  },
  logOut() {
    return request({
      url: '/auth/logout',
      method: 'get'
    })
  },
  distLogout(url) {
    return request({
      url,
      method: 'get'
    })
  }
}
