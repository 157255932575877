<template>
    <div class="container">
        <Header v-if="serviceLoaded"></Header>
        <el-container v-if="serviceLoaded" class="container">
            <SideBar></SideBar>
            <Container></Container>
        </el-container>
    </div>
</template>

<script setup>
  import Container from './container.vue'
  import Header from './header/index.vue'
  import SideBar from './sidebar.vue'

  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()

  const serviceLoaded = ref(false)

  import { showLoading, hideLoading } from '@/utils/loader-service'

  onMounted(async() => {
    showLoading()

    const tasks = [
      // this.$store.dispatch('app/get_settings'),
      (['super_admin', 'service_desk'].includes(store.getters['user/role'])) ? store.dispatch('shared_session/load_shared_session') : true
    ]

    await Promise.all(tasks)

    serviceLoaded.value = true
    hideLoading()

  })

</script>

<style>

    .container {
        width: 1240px;
        margin: 0 auto;
        border: 1px solid #eee
    }

    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }

    .el-aside {
        color: #333;
    }
</style>
