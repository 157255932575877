<template>
    <el-row class="auth">
        <el-col :span="24">
            <div v-if="!nick">
                You are not authorized,
                <el-button class="auth_button" @click="signIn" type="primary">Authorize</el-button>
                first
            </div>
            <div v-else>
                Welcome back, <b>{{ nick }}</b>
                <el-button class="auth_button" @click="signOut" type="primary">LogOut</el-button>
            </div>
        </el-col>
    </el-row>
</template>

<script setup>
  import api from '@/api/user'
  import SharedSession from './SharedSession.vue'
  import { useStore } from 'vuex'

  const store = useStore()

  import { computed } from 'vue'

  import { useRouter } from 'vue-router'

  const router = useRouter()

  const nick = computed(() => store.state.user.nick)
  const shared_session = computed(() => store.state.shared_session)

  const settings = computed(() => store.getters['app/settings'])
  const corezoid_host = computed(() => settings.value.web_settings.host.auth)
  const sa_host = computed(() => settings.value.web_settings.host.single_account)
  const isSa = computed(() => settings.value.auth_single_account)

  const signInBySAUrl = () => {
    return 'https://' + corezoid_host.value + '/auth2/single_account?redirect_uri=' + encodeURIComponent(location.href)
  }

  const signOutBySAUrl = () => {
    return 'https://' + sa_host.value + '/auth/dist_logout?redirect_uri=' + encodeURIComponent(location.href)
  }

  const signInByCorezoidUrl = () => {
    return 'https://' + corezoid_host.value + '/login/' + encodeURIComponent(location.href)
  }

  const signIn = () => {
    location.href = isSa.value ? signInBySAUrl() : signInByCorezoidUrl()
  }

  const signOut = async() => {
    await api.logOut()
    if (isSa.value) {
      location.href = signOutBySAUrl()
    } else {
      router.push({ name: 'default' })
      location.reload()
    }
  }
</script>


<style scoped>
    div.auth {
        font-size: 10pt;
        text-align: right;
    }

    button.auth_button {
        margin: 0 5px 0 5px
    }
</style>