import fetchInstance from '@/utils/request'
import { ElMessage } from 'element-plus'

import { publicPath } from '../../vue.config.js'

export function deepClone(obj) {
  const _out = new obj.constructor()

  const getType = function(n) {
    return Object.prototype.toString.call(n).slice(8, -1)
  }

  for (const _key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, _key)) {
      _out[_key] = getType(obj[_key]) === 'Object' || getType(obj[_key]) === 'Array' ? deepClone(obj[_key]) : obj[_key]
    }
  }
  return _out
}

export function timeConverter(UNIX_timestamp) {
  const change = (val) => (val <= 9) ? '0' + val : val

  var a = new Date(UNIX_timestamp * 1000)
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var hour = a.getHours()
  var min = a.getMinutes()
  var sec = a.getSeconds()
  var time = change(date) + ' ' + change(month) + ' ' + year + ' ' + change(hour) + ':' + change(min) + ':' + change(sec)

  return time
}

import moment from 'moment'

export function toMoment(val) {
  return moment(val * 1000).format('YYYY-MM-DD, HH:mm:ss')
}

export function stateMerge(state, value, propName, ignoreNull) {
  if (
    Object.prototype.toString.call(value) === '[object Object]' &&
    (propName === null || Object.prototype.hasOwnProperty.call(state, propName))
  ) {
    const o = propName === null ? state : state[propName]
    if (o !== null) {
      for (const prop in value) {
        stateMerge(o, value[prop], prop, ignoreNull)
      }
      return
    }
  }
  if (!ignoreNull || value !== null) state[propName] = value
}

export function request(config, ops = { showGlobalError: true }) {
  return new Promise((resolve, reject) =>
    fetchInstance[config.method.toLowerCase()](config.url, config.params || config.data)
      .then(data => {
        resolve({ data })
      })
      .catch(error => {
        if (typeof error.response === 'object') {
          resolve(error.response)
        } else {
          if (ops.showGlobalError) {
            ElMessage({
              message: error.message,
              type: 'error',
              duration: 5 * 1000
            })
          }
          reject(error)
        }
      })
  )
}

export function get_path(path) {
  const superadmin_path = publicPath
  return (superadmin_path === '/' ? '' : superadmin_path) + path
}