import { request, get_path } from '@/utils/util'

export default {
  fetchList() {
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'list',
        obj: 'values'
      }
    })
  },
  listOfHistory(key) {
    const limit = 10
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'list',
        obj: 'history',
        key,
        limit
      }
    })
  },
  setValue(item, newValue) {
    item.value = (newValue !== '') ? newValue : null
    return request({
      url: get_path('/api/1/json'),
      method: 'post',
      data: {
        type: 'set',
        obj: 'value',
        ...item
      }
    })
  }
}
