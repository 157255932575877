import { createStore } from 'vuex'

import app from './modules/app'
import user from './modules/user'
import server_license from './modules/server_license.js'
import shared_session from './modules/shared_session.js'
import components from './modules/components.js'
import limits from './modules/limits.js'

export default new createStore({
  modules: {
    app,
    user,
    shared_session,
    server_license,
    components,
    limits
  }
})